$gray-1: #f9f9f9;
$gray-2: #ccc;
$gray-3: #767676;
$gray-4: #515151;
$gray-5: #313131;

$red: #ac4142;
$orange: #d28445;
$yellow: #f4bf75;
$green: #90a959;
$cyan: #75b5aa;
$blue: #268bd2;
$dark-blue: #3863d1;
$brown: #8f5536;

$root-font-family: 'Oxygen', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$root-font-size: 14px;
$root-line-height: 1.5;

$frontpage-font-family: 'Assistant', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$large-breakpoint: 38em;
$large-font-size: 16px;

$code-font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace !default;
$code-line-height: 1.5;

// Light theme

$body-color-light: #515151;
$secondary-color-light: #838383;
$body-bg-light: #fff;
$link-color-light: $dark-blue;
$border-color: #e5e5e5;
$code-color: #bf616a;

// Dark theme

$body-color-dark: rgb(203, 199, 191);
$secondary-color-dark: rgb(156, 154, 148);
$body-bg-dark: rgb(30, 32, 34);

$link-color-dark: #6689e0;