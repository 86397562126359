/// Layout stuff for the frontpage

.frontpage {
  font-family: $frontpage-font-family;
}

.frontpage > .jumbotron {
  background-color: var(--bg-color);
}

.icon {
  display: inline-block;
  height: 12rem;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

html,
body,
main {
  min-height: 100% !important;
  height: 100%;
}

.main-category-element.text {
  font-size: 1.8rem;
  text-align: center;
}

.main-category-container {
  display: flex;
  flex-direction: column;
}

.footer-icon {
  width: 2.6rem;
  height: 2.6rem;
  margin: 1rem;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

.header {
  font-size: 2.5rem;
  font-weight: bold;
}

.footer {
  margin: auto;
  display: flex;  
  text-align: center;
  align-content: center;
  
}

.footer-contents {
  align-self: flex-end;
  font-size: 0.8rem;
  text-align: center;
}

.zooming > a {
  color: inherit;
  text-decoration: none;
}
.zooming > a:hover {
  color: inherit;
  text-decoration: none;
}

hr {
  border: 0; 
  height: 1px;
  background-image: -webkit-linear-gradient(left, var(--bg-color), var(--primary-color), var(--bg-color));
  // background-image: -moz-linear-gradient(left, var(--bg-color), var(--primary-color), var(--bg-color));
  // background-image: -ms-linear-gradient(left, var(--bg-color), var(--primary-color), var(--bg-color));
  // background-image: -o-linear-gradient(left, var(--bg-color), var(--primary-color), var(--bg-color)); 
  margin-top: 1rem;
  margin-bottom: -0.3rem;
  z-index: -1;
}

.frontpage .theme-switcher-icon {
  // margin-top: -500rem;
  padding-bottom: 1rem;
  display: inline-block;
  stroke-width: 0;
  stroke: var(--secondary-color);
  fill: var(--secondary-color);
}

.frontpage .theme-switcher-icon-inner {
  width: 3rem;
  height: 3rem;
}