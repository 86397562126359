// Messages
//
// Show alert messages to users. You may add it to single elements like a `<p>`,
// or to a parent if there are multiple elements to show.

.message {
  margin-bottom: 1rem;
  padding: 1rem;
  color: var(--primary-color);
  background-color: var(--bg-color);
}
