// Posts and pages
//
// Each post is wrapped in `.post` and is used on default and post layouts. Each
// page is wrapped in `.page` and is only used on the page layout.

.page,
.post {
  margin-bottom: 2em;

  li + li {
    margin-top: .25rem;
  }
}

// Blog post or page title
.page-title,
.post-title,
.post-title a {
  color: var(--heading-color);
}
.page-title,
.post-title {
  margin-top: 0;
}

// Meta data line below post title
.post-date {
  display: block;
  margin-top: -.5rem;
  margin-bottom: 1rem;
  color: var(--secondary-color);
}


// Related posts
.related {
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-top: 1px solid var(--secondary-color);
  border-bottom: 1px solid var(--secondary-color);
}

.related-posts {
  padding-left: 0;
  list-style: none;

  h3 {
    margin-top: 0;
  }

  li {
    small {
      font-size: 75%;
      color: var(--secondary-color);
    }

    a:hover {
      color: var(--link-color);
      text-decoration: none;

      small {
        color: inherit;
      }
    }
  }
}
