@use "mixins";

.masthead {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

.masthead .theme-switcher-icon {
  vertical-align: bottom;
}

.masthead-title {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--heading-color);
  margin-left: -0.2rem; // Compensating for links zooming
  display: flex;
  flex-wrap: wrap;
  
  a {
    color: inherit;
  }

  small {
    font-size: 75%;
    font-weight: 400;
    color: var(--secondary-color);
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: -0.2rem;  // Compensating for links zooming
  }
}

.masthead-row {
  padding-bottom: 0.4rem;
  display: flex;
}

.category-link {
  font-size: 90%;
  font-weight: 400;
  color: var(--secondary-color);
  width: fit-content;
  display: inline-block;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  cursor: pointer;
}

.category-link.active {
  font-weight: bold;
}

[data-theme="dark"] {
  .theme-switcher-icon-inner.icon-sun {
    display: block;
  }
  
  .theme-switcher-icon-inner.icon-moon {
    display: none;
  }
}

[data-theme="light"] {
  .theme-switcher-icon-inner.icon-sun {
    display: none;
  }

  .theme-switcher-icon-inner.icon-moon {
    display: block;
  }
}

.masthead .push-next-row-right {
  margin-right: auto;
}

.masthead .header-icon {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  cursor: pointer;
  display: block;
}

.header-icon {
  width: 1.8rem;
  height: 1.8rem;
  stroke-width: 0;
  stroke: var(--primary-color);
  fill: var(--primary-color);
  color: var(--primary-color);
  opacity: 0.75;
  cursor: pointer;
}

.social-media-bar {
  display: flex;
  margin-top: 0.4rem;
}

// Media queries for replacing text with icons
@media (min-width: 729px) {
  .text-replaceable-by-icon
  {
    display: inline-block;
  }
  .icon-replaceable-by-text {
    display: none;
  }
  .social-media-bar {
    display: flex;
  }
  .collapsible-outer {
    display: none;
  }
}
@media (max-width: 730px) {
  .text-replaceable-by-icon
  {
    display: none
  }
  .icon-replaceable-by-text {
    display: inline-block;
  }
  .social-media-bar {
    display: none;
  }
  .collapsible-outer {
    @include inline-flex;
  }
}

.collapsed-menu {
  background: var(--bg-color);
  box-shadow: 0.5rem 0.5rem 1rem rgba(0,0,0,.5);
  padding: 0.5rem;
  left: -1rem;
  align-items: center;
  flex-direction: column;
  visibility: hidden;
  opacity: 0;
  // transition: visibility 0.3s ease-in-out,opacity 0.3s ease-in-out;
  z-index: 2;
  position: absolute;
}

.collapsed-menu.visible {  
  visibility: visible;
  opacity: 1;
}

.collapsed-menu.invisible {
  opacity: 0;
  visibility: hidden;
}

.icon-in-column-menu {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  align-items: center;
  width: 2.3rem;
  height: 2.3rem;
}

.sections {
  display: flex;
  margin-top: 0.27rem;
  flex-wrap: wrap;
  align-content: end;
  align-items: center;
  justify-content: center;
}
