// Body resets
//
// Update the foundational and global aspects of the page.


// Configure dark/light theme colors
:root {
  --primary-color: #{$body-color-light};
  --secondary-color: #{$secondary-color-light};
  --font-color: #{$body-color-light};
  --bg-color: #{$body-bg-light};
  --heading-color: #{$body-color-light};
  --link-color: #{$link-color-light};
}
[data-theme="dark"] {
  --primary-color: #{$body-color-dark};
  --secondary-color: #{$secondary-color-dark};
  --font-color: #{$body-color-dark};
  --bg-color: #{$body-bg-dark};
  --heading-color: #{$body-color-dark};
  --link-color: #{$link-color-dark};
}

* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  font-family: $root-font-family;
  font-size: $root-font-size;
  line-height: $root-line-height;

  @media (min-width: $large-breakpoint) {
    font-size: $large-font-size;
  }
}

body {
  color: var(--primary-color);
  background-color: var(--bg-color);
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
}

// No `:visited` state is required by default (browsers will use `a`)
a {
  color: var(--link-color);
  text-decoration: none;

  // `:focus` is linked to `:hover` for basic accessibility
  &:hover,
  &:focus {
    text-decoration: underline;
  }

  strong {
    color: inherit;
  }
}

img {
  display: block;
  max-width: 100%;
  margin: 0 0 1rem;
  border-radius: 0;
}

img.emoji {
  display: inline;
  max-width: 100%;
  margin: 0;
  border-radius: 5px;
  margin-bottom: -0.2rem;
}

table {
  margin-bottom: 1rem;
  width: 100%;
  font-size: 85%;
  border: 1px solid var(--primary-color);
  border-collapse: collapse;
}

td,
th {
  padding: .25rem .5rem;
  border: 1px solid var(--primary-color);
}

th {
  text-align: left;
}

tbody tr:nth-child(odd) td,
tbody tr:nth-child(odd) th {
  background-color: var(--primary-color);
  color: var(--bg-color);
}

.zooming {
  transition: transform 1s ease;
  transform: scale(.9, .9);  
}

.zooming:hover {
  transform: scale(1, 1);
}

// Cookies

.cc-revoke {
  background-color: var(--primary-color);
  color: var(--bg-color);
}

.cc-window {  
  background-color: var(--primary-color);
  color: var(--bg-color);
}

.cc-compliance .cc-btn {
  color: var(--bg-color);
  white-space: break-spaces;
}

.email-svg {     
  color: var(--primary-color);
  stroke: var(--primary-color);
  fill: var(--primary-color);
  height: 0.5rem;
  width: 9rem;
}

.emoji {
  display: inline;
  margin: 0 0 -.13em;
  height: 1em;
  width: 1em;
}
